import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Spin,
    Switch,
    message,
} from 'antd'

import { UIDatePicker, UIDrawerForm } from '../../../components'
import { NumericFormat } from 'react-number-format'
import { paymentService } from 'redux/services'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Create extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isSending: false,
            value: 0,
            id: 0,
        }
    }

    onOpen = ({ id }) => {
        this.setState({
            id: id,
        })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const data = {
            ...values,
            order_id: this.state.id,
            value: this.state.value,
        }

        paymentService
            .create(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro cadastrado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { visible, userData } = this.props

        const { isLoading, isSending, value } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={500}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title="Inserir nova compensação"
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                    initialValues={{
                        stage_id: 1,
                    }}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="value"
                                label="Valor da compensação/restituição"
                                hasFeedback
                            >
                                <NumericFormat
                                    className="ant-input ant-input-outlined"
                                    prefix="R$"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    value={this.state.value}
                                    onValueChange={({ floatValue }) => {
                                        this.setState({
                                            value: floatValue,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="received_at"
                                label="Data de compensação"
                            >
                                <UIDatePicker
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="received"
                                label="Deseja marcar como recebido?"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="paid"
                                label="Deseja criar comissão ao franqueado referente a esse pagamento?"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row> */}
                </Form>
                <Modal open={this.state.iFrame}>
                    <iframe src={this.state.iFrame} />
                </Modal>
            </UIDrawerForm>
        )
    }
}

export default Create
