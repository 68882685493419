import React, { PureComponent } from 'react'
import moment from 'moment'
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar'
import { UILayout } from '../../components'
import {
    Button,
    Card,
    Col,
    Dropdown,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Spin
} from 'antd'
import { connect } from 'react-redux'
import { generalActions } from '../../redux/actions'
import {
    orderService,
    stageService
} from '../../redux/services'
import ModalCreate from '../Orders/create'
import ModalShow from '../Orders/show'
import ModalEdit from '../Orders/edit'
import ModalFilters from '../Orders/filters'
import ModalStage from '../Orders/stage'
import ModalConfig from './config'
import { number_format } from 'helpers/phpjs'
import ModalComment from '../Orders/comment'
import { ControlledBoard } from '@caldwell619/react-kanban'
import '@caldwell619/react-kanban/dist/styles.css'
import { refreshUserData } from 'redux/actions/authActions'
import DateFilter from './dateFilter'

const config = {
    title: 'Vendas',
    permissionPrefix: 'orders',
    list: 'orders',
    searchPlaceholder: 'Buscar por empresa ou cnpj',
    orders: [
        {
            label: 'Mais recentes',
            field: 'id',
            sort: 'desc',
            default: true,
        },
        {
            label: 'Mais antigos',
            field: 'id',
            sort: 'asc',
        },
    ],
}

class Orders extends PureComponent {
    constructor(props) {
        super(props)
        const defaultOrder = config.orders.find((o) => o.default)
        // Define o filtro padrão de data para 7 dias (de 7 dias atrás até hoje)
        const defaultCreatedAt = [moment().subtract(7, 'days').startOf('day'), moment()]
        this.state = {
            isLoading: false,
            listType: 'list',
            data: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            orderByLabel: defaultOrder.label,
            orderByField: defaultOrder.field,
            orderBySort: defaultOrder.sort,
            search: '',
            // Actions
            commentModalVisible: false,
            createModalVisible: false,
            showModalVisible: false,
            editModalVisible: false,
            stageModalVisible: false,
            configModalVisible: false,
            exportModalVisible: false,
            filtersModalVisible: false,
            activeLoadings: [],
            totalItems: 0,
            isExporting: false,
            // Filtros – created_at já vem como 7 dias por padrão
            totalFilters: 0,
            filters: {
                signed: null,
                created_at: defaultCreatedAt,
                stage_id: this.props.router?.location?.state?.stage_id ?? null,
                user_id: null,
                service_id: null,
            },
            board: {
                columns: [],
            },
            services: [],
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.listType && state.listType !== props.listType) {
            return { listType: props.listType }
        }
        return null
    }

    componentDidMount() {
        const defaultCreatedAt = this.state.filters.created_at
        this.filtersOnComplete({
            ...this.state.filters,
            stage_id: this.props.router?.location?.state?.stage_id ?? null,
            signed: this.props.router?.location?.state?.signed ?? null,
            created_at: this.props.router?.location?.state?.created_at ?? defaultCreatedAt,
            user_id: this.props.router?.location?.state?.user_id ?? null,
            service_id: this.props.router?.location?.state?.service_id ?? null,
        })
        // Opcional: chamar fetchGetAll aqui, se desejar
        // this.fetchGetAll(true)
    }

    componentDidUpdate(prevProps) {
        const prevSteps = prevProps.userData.viewConfig?.steps_view
        const currentSteps = this.props.userData.viewConfig?.steps_view
        if (JSON.stringify(prevSteps) !== JSON.stringify(currentSteps)) {
            this.fetchGetAll(true)
        }
    }

    // Extraindo métodos para evitar criação de funções inline

    handleSearchPressEnter = (e) => {
        e.preventDefault()
        this.props.onSearch(e.target.value)
        return false
    }

    handleDateFilterChange = (dateRange) => {
        this.setState(
            (prev) => ({
                filters: { ...prev.filters, created_at: dateRange },
            }),
            () => this.fetchGetAll(true)
        )
    }

    handleNavigateHome = () => {
        this.props.router.navigate('/')
    }

    menuItem = (item) => (
        <Menu className="actions-dropdown-menu">
            {item.stage_id === 3 && !item.signed && (
                <Menu.Item key="signed">
                    <a onClick={() => this.onSignOrder(item)}>
                        <i className="fal fa-check-square"></i>
                        Assinar
                    </a>
                </Menu.Item>
            )}
            <Menu.Item key="show">
                <a onClick={() => this.showOpen(item)}>
                    <i className="fal fa-file" />
                    Visualizar
                </a>
            </Menu.Item>
            <Menu.Item key="edit">
                <a onClick={() => this.editOpen(item)}>
                    <i className="fal fa-pen" />
                    Editar
                </a>
            </Menu.Item>
            <Menu.Item key="comment">
                <a onClick={() => this.commentOpen(item)}>
                    <i className="fal fa-comment"></i>Comentário
                </a>
            </Menu.Item>
            <Menu.Item key="stage" className="divider">
                <a onClick={() => this.stageOpen(item)}>
                    <i className="fal fa-walking"></i>
                    Etapa
                </a>
            </Menu.Item>
            {(this.props.userData.role === 'admin' ||
                this.props.userData.role === 'collaborator_admin') && (
                    <Menu.Item key="delete" className="divider btn-delete">
                        <a onClick={() => this.deleteConfirm(item)}>
                            <i className="fal fa-trash"></i>
                            Excluir
                        </a>
                    </Menu.Item>
                )}
        </Menu>
    )

    columns = () => [
        {
            title: 'Empresa',
            render: (item) => item.customer?.corporate_name || '',
        },
        {
            title: 'CNPJ',
            width: 200,
            render: (item) => item.customer?.document || '',
        },
        {
            title: 'Franqueado',
            width: 200,
            render: (item) => item.franchisee?.name || '',
        },
        {
            title: 'Créd. estimado',
            width: 120,
            render: (item) => (
                <span title={item.estimated_credit}>
                    R$ {number_format(item.estimated_credit, 2, ',', '.')}
                </span>
            ),
        },
        {
            title: 'Créd. apurado',
            width: 120,
            render: (item) => (
                <span title={item.credit}>
                    R$ {number_format(item.credit, 2, ',', '.')}
                </span>
            ),
        },
        {
            title: 'Serviço',
            width: 100,
            render: (item) => item.service?.name || '',
        },
        {
            title: 'Etapa',
            width: 100,
            render: (item) => item.stage?.name || '',
        },
        {
            title: 'Contrato',
            width: 60,
            className: 'active no-ellipsis',
            render: (item) =>
                this.state.activeLoadings.indexOf(item.id) !== -1 ? (
                    <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} />
                ) : (
                    <>
                        {item.signed ? (
                            <i className="fal fa-check-circle" style={{ color: 'green' }}></i>
                        ) : (
                            <i className="fal fa-times-circle" style={{ color: 'red' }}></i>
                        )}
                    </>
                ),
        },
        {
            title: 'Ações',
            className: 'actions no-ellipsis',
            visible: true,
            render: (item) => (
                <Dropdown
                    overlay={this.menuItem(item)}
                    className="actions-dropdown"
                    placement="bottomRight"
                    trigger={['click']}
                >
                    <Button icon={<i className="fal fa-ellipsis-v" />} />
                </Dropdown>
            ),
        },
    ]

    fetchGetAll = (init = false, exportItems = false) => {
        const { pagination, orderByField, orderBySort, search, filters } = this.state
        const { userData } = this.props
        const {
            viewConfig: { steps_view }
        } = userData

        this.setState(exportItems ? { isExporting: true } : { isLoading: true })

        const data = {
            orderBy: `${orderByField}:${orderBySort}`,
            search: search,
            is_kanban: true,
        }

        if (userData.role !== 'admin' && userData.role !== 'collaborator_admin') {
            const user_id = this.props.router?.location?.state?.userId
            if (user_id) data.user_id = user_id
        } else if (filters.user_id !== null) {
            data.user_id = filters.user_id
        }

        if (exportItems) {
            data.exportItems = true
        } else {
            data.page = init ? 1 : pagination.current
            data.limit = pagination.pageSize
        }

        if (filters.signed !== null) data.signed = filters.signed
        if (filters.stage_id !== null) data.stage_id = filters.stage_id
        if (filters.service_id !== null) data.service_id = filters.service_id

        if (filters.created_at) {
            data.created_at = [
                filters.created_at[0]
                    .clone()
                    .startOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
                filters.created_at[1]
                    .clone()
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
            ]
        }

        stageService
            .getAll()
            .then((response) => {
                const columns = response.data.data
                    .filter((stage) => {
                        if (Array.isArray(steps_view) && steps_view.length > 0) {
                            return steps_view.includes(stage.id)
                        }
                        return true
                    })
                    .map((stage) => ({
                        id: stage.id,
                        title: stage.name,
                        cards: [],
                    }))

                this.setState({ board: { columns } })
                return orderService.getAll(data)
            })
            .then((response) => {
                const items = response.data
                this.setState({ totalItems: items.length })
                const columns = this.state.board.columns
                items.forEach((item) => {
                    const column = columns.find((c) => c.id === item.stage_id)
                    if (column) {
                        column.cards.push({
                            id: item.id,
                            title: item.customer?.corporate_name,
                            description: '',
                            ...item,
                        })
                    }
                })
                this.setState({ board: { columns }, isLoading: false })
            })
            .catch((error) => {
                this.setState({ isLoading: false })
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(error),
                })
            })
    }

    onPaginationChange = (page) => {
        this.setState(
            (state) => ({
                pagination: { ...state.pagination, current: page },
            }),
            () => this.fetchGetAll()
        )
    }

    onOrderChange = (value) => {
        const defaultOrder = config.orders.find(
            (o) => `${o.field}:${o.sort}` === value
        )
        if (!defaultOrder) return
        this.setState(
            {
                orderByLabel: defaultOrder.label,
                orderByField: defaultOrder.field,
                orderBySort: defaultOrder.sort,
            },
            () => this.fetchGetAll(true)
        )
    }

    onSearch = (value) => {
        this.setState({ client_name: value }, () => this.fetchGetAll(true))
    }

    onSearchChange = (e) => {
        if (!e.hasOwnProperty('type')) {
            this.setState({ search: e.target.value }, () => {
                if (e.target.value) this.fetchGetAll(true)
            })
        }
    }

    onSignOrder = ({ id }) => {
        const { activeLoadings } = this.state
        if (activeLoadings.indexOf(id) === -1) activeLoadings.push(id)
        this.setState({ activeLoadings })
        orderService
            .edit({ id, signed: true })
            .then(() => {
                const newData = [...this.state.data]
                const index = newData.findIndex((item) => id === item.id)
                if (index !== -1) {
                    const item = newData[index]
                    newData.splice(index, 1, { ...item, signed: true })
                    this.setState({ data: newData })
                }
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
            .finally(() => {
                const index = activeLoadings.indexOf(id)
                if (index !== -1) {
                    activeLoadings.splice(index, 1)
                    this.setState({ activeLoadings })
                }
            })
    }

    commentOpen = ({ customer_id }) => {
        this.setState({ commentModalVisible: true })
        this.commentScreen.onOpen(customer_id)
    }

    commentOnClose = () => {
        this.setState({ commentModalVisible: false })
    }

    commentOnComplete = () => {
        this.setState({ commentModalVisible: false })
        this.fetchGetAll(true)
    }

    showOpen = ({ id }) => {
        this.setState({ showModalVisible: true })
        this.showScreen.onOpen(id)
    }

    showOnClose = () => {
        this.setState({ showModalVisible: false })
    }

    deleteConfirm = ({ id }) => {
        Modal.confirm({
            title: 'Confirmar exclusão!',
            content: 'Tem certeza de que deseja excluir este registro?',
            okText: 'Excluir',
            autoFocusButton: null,
            onOk: () => this.deleteConfirmed(id),
        })
    }

    deleteConfirmed = (id) => {
        return orderService
            .destroy({ id })
            .then(() => this.fetchGetAll())
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    createOpen = () => {
        this.setState({ createModalVisible: true })
        this.createScreen.onOpen()
    }

    createOnClose = () => {
        this.setState({ createModalVisible: false })
    }

    createOnComplete = () => {
        this.setState({ createModalVisible: false })
        this.fetchGetAll(true)
    }

    editOpen = ({ id }) => {
        this.setState({ editModalVisible: true })
        this.editScreen.onOpen(id)
    }

    editOnClose = () => {
        this.setState({ editModalVisible: false })
    }

    editOnComplete = () => {
        this.setState({ editModalVisible: false })
        this.fetchGetAll()
    }

    stageOpen = (item) => {
        this.setState({ stageModalVisible: true })
        this.stageScreen.onOpen(item)
    }

    stageOnClose = () => {
        this.setState({ stageModalVisible: false })
    }

    stageOnComplete = () => {
        this.setState({ stageModalVisible: false })
        this.fetchGetAll()
    }

    configOpen = (item) => {
        this.setState({ configModalVisible: true })
        this.configScreen.onOpen(item)
    }

    configOnClose = () => {
        this.setState({ configModalVisible: false })
    }

    configOnComplete = () => {
        this.setState({ configModalVisible: false })
    }

    filtersOpen = () => {
        this.setState({ filtersModalVisible: true })
        this.filtersScreen.onOpen({ ...this.state.filters })
    }

    filtersOnClose = () => {
        this.setState({ filtersModalVisible: false })
    }

    filtersOnComplete = (filters) => {
        this.setState({ filtersModalVisible: false })
        this.setState(
            {
                totalFilters: Object.keys(filters).filter(key => filters[key] !== null).length,
                filters: filters,
            },
            () => this.fetchGetAll(true)
        )
    }

    handleCardMove = (card, from, to) => {
        const previousBoard = JSON.parse(JSON.stringify(this.state.board))
        const updatedCard = { ...card, stage_id: to.toColumnId }
        const newBoard = {
            columns: this.state.board.columns.map((column) => {
                if (column.id === from.fromColumnId) {
                    return { ...column, cards: column.cards.filter((c) => c.id !== card.id) }
                } else if (column.id === to.toColumnId) {
                    const newCards = [...column.cards]
                    if (typeof to.index === 'number') {
                        newCards.splice(to.index, 0, updatedCard)
                    } else {
                        newCards.push(updatedCard)
                    }
                    return { ...column, cards: newCards }
                }
                return column
            }),
        }
        this.setState({ board: newBoard, isSending: true })
        Modal.confirm({
            title: 'Confirmar mudança de etapa?',
            content: 'Você está prestes a mover o card. Tem certeza?',
            onOk: () => {
                return orderService.edit(updatedCard)
                    .then(() => {
                        message.success('Registro atualizado com sucesso.')
                        this.setState({ isSending: false })
                    })
                    .catch((error) => {
                        this.setState({ board: previousBoard, isSending: false })
                        Modal.error({
                            title: 'Ocorreu um erro!',
                            content: String(error),
                        })
                    })
            },
            onCancel: () => {
                this.setState({ board: previousBoard, isSending: false })
                message.info('Movimentação cancelada.')
            },
        })
    }

    renderCard = (card) => (
        <Card
            className="card-kanban"
            title={card.title}
            extra={
                <Dropdown
                    ref={(el) => (this.dropdown = el)}
                    overlay={this.menuItem(card)}
                    placement="bottomRight"
                    trigger={['click']}
                >
                    <div className="card-button">
                        <i className="fal fa-ellipsis-v" style={{ color: '#000', fontSize: 30 }} />
                    </div>
                </Dropdown>
            }
        >
            <div className="item-card">
                <p className="title">Franqueado</p>
                <p className="text">{card.franchisee?.name}</p>
            </div>
            <div className="item-card">
                <p className="title">Crédito Estimado</p>
                <p className="text">R${number_format(card.estimated_credit, 2, ',', '.')}</p>
            </div>
            <div className="item-card">
                <p className="title">Crédito Apurado</p>
                <p className="text">R${number_format(card.credit, 2, ',', '.')}</p>
            </div>
            <div className="item-card">
                <p className="title">Serviço</p>
                <p className="text">{card?.service?.name}</p>
            </div>
            <div className="item-card">
                <p className="title">Assinado</p>
                <p className="text">
                    {card.signed ? (
                        <i className="fal fa-check-circle" style={{ color: 'green' }}></i>
                    ) : (
                        <i className="fal fa-times-circle" style={{ color: 'red' }}></i>
                    )}
                </p>
            </div>
        </Card>
    )

    renderExtra = () => (
        <Row gutter={24} justify="space-between" align="middle">
            <Col>
                <Row gutter={12} align="middle">
                    <Col>
                        <Input.Search
                            style={{ width: 350 }}
                            placeholder="Buscar por empresa ou cnpj"
                            onSearch={this.onSearch}
                            onChange={this.onSearchChange}
                            allowClear
                            loading={this.props.isLoading}
                            onPressEnter={this.handleSearchPressEnter}
                        />
                    </Col>
                    <Col>
                        <Button onClick={this.filtersOpen} icon={<i className="fas fa-filter"></i>} />
                    </Col>
                    <Col>
                        <h2 style={{ margin: '0 40px' }}>Total: {this.state.totalItems}</h2>
                    </Col>
                    <Col>
                        <DateFilter
                            onDateFilterChange={this.handleDateFilterChange}
                            openFiltersModal={this.filtersOpen}
                        />
                    </Col>
                </Row>
            </Col>
            <Col>
                <Row gutter={12}>
                    <Col>
                        <Button onClick={this.handleNavigateHome} icon={<i className="far fa-home"></i>} type="primary">
                            Voltar para home
                        </Button>
                    </Col>
                    <Col style={{ alignSelf: 'flex-end' }}>
                        <Button onClick={this.configOpen} icon={<i className="fas fa-cog"></i>} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )

    render() {
        const { board, isLoading } = this.state
        const {
            userData: { viewConfig: { steps_view } }
        } = this.props

        return (
            <UILayout>
                <DashboardNavbar absolute isMini extra renderExtra={this.renderExtra()} />
                <div className="page page-kanban">
                    {isLoading ? (
                        <div className="loading-container" style={{ marginTop: -200 }}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <ControlledBoard
                            style={{ height: '100%' }}
                            onCardDragEnd={this.handleCardMove}
                            renderCard={this.renderCard}
                            allowAddCard={false}
                            allowAddColumn={false}
                            allowRemoveColumn={false}
                            renderColumnHeader={(column) => (
                                <div className="column-header">
                                    <h3>{column.title}</h3>
                                    <h3>Total: {column.cards.length}</h3>
                                </div>
                            )}
                        >
                            {this.state.board}
                        </ControlledBoard>
                    )}
                </div>
                <ModalShow ref={(el) => (this.showScreen = el)} visible={this.state.showModalVisible} onClose={this.showOnClose} />
                <ModalCreate
                    ref={(el) => (this.createScreen = el)}
                    visible={this.state.createModalVisible}
                    onComplete={this.createOnComplete}
                    onClose={this.createOnClose}
                    userData={this.props.userData}
                />
                <ModalFilters
                    ref={(el) => (this.filtersScreen = el)}
                    visible={this.state.filtersModalVisible}
                    onComplete={this.filtersOnComplete}
                    onClose={this.filtersOnClose}
                />
                <ModalEdit
                    ref={(el) => (this.editScreen = el)}
                    visible={this.state.editModalVisible}
                    onComplete={this.editOnComplete}
                    onClose={this.editOnClose}
                    userData={this.props.userData}
                />
                <ModalStage
                    ref={(el) => (this.stageScreen = el)}
                    visible={this.state.stageModalVisible}
                    onComplete={this.stageOnComplete}
                    onClose={this.stageOnClose}
                />
                <ModalConfig
                    ref={(el) => (this.configScreen = el)}
                    visible={this.state.configModalVisible}
                    onComplete={this.configOnComplete}
                    onClose={this.configOnClose}
                    config={steps_view}
                    refreshUserData={this.props.refreshUserData}
                />
                <ModalComment
                    ref={(el) => (this.commentScreen = el)}
                    visible={this.state.commentModalVisible}
                    onComplete={this.commentOnComplete}
                    onClose={this.commentOnClose}
                    userData={this.props.userData}
                />
            </UILayout>
        )
    }
}

const mapStateToProps = (state) => ({
    permissions: state.auth.userData.permissions,
    listType: state.general.listType[config.list],
    userData: state.auth.userData,
})

const mapDispatchToProps = (dispatch) => ({
    refreshUserData: () => dispatch(refreshUserData()),
    onChangeListType: (type) => dispatch(generalActions.changeListType(config.list, type)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
