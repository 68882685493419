import Dashboard from 'layouts/dashboard'
// import Billing from 'layouts/billing'
import RTL from 'layouts/rtl'
import Notifications from 'layouts/notifications'
import Profile from 'layouts/profile'

// @mui icons
import Login from '../layouts/Login'
import Franchisees from '../layouts/Franchisees'
import Customers from '../layouts/Customers'
import FranchiseeShow from '../layouts/FranchiseeShow'
import Services from '../layouts/Services'
import Teams from '../layouts/Teams'
import Units from 'layouts/Units'
import CustomersByFranchisee from 'layouts/CustomersByFranchisee'
import Orders from 'layouts/Orders'
import OrdersKanban from 'layouts/OrdersKanban'
import Finances from 'layouts/Finances'
import Collaborators from 'layouts/Collaborators'
import ChangePassword from 'layouts/ChangePassword'
import Consultants from 'layouts/Consultants'
import Training from 'layouts/Training'
import Watching from 'layouts/Watching'
import TrainingUser from 'layouts/TrainingUser'
import Posts from 'layouts/Posts'
import Leads from 'layouts/Leads'
import Steps from 'layouts/Steps'

const paths = [
    {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        path: '/',
        component: <Dashboard />,
        index: true,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        path: '/dashboard/:id',
        component: <Dashboard />,
        index: true,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Franqueados',
        key: 'franchisees',
        path: '/franqueados',
        component: <Franchisees />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Detalhes do franqueado',
        key: 'franchiseeShow',
        path: '/franqueados-detalhe',
        component: <FranchiseeShow />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Serviços',
        key: 'services',
        path: '/servicos',
        component: <Services />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Times',
        key: 'teams',
        path: '/times',
        component: <Teams />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Clientes',
        key: 'customers',
        path: '/clientes',
        component: <Customers />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Clientes',
        key: 'customersByFranchisee',
        path: '/clientes-franqueado',
        component: <CustomersByFranchisee />,
        logged: true,
    },
    // {
    //     type: 'collapse',
    //     name: 'Billing',
    //     key: 'billing',
    //     path: '/billing',
    //     component: <Billing />,
    //     logged: true,
    // },
    {
        type: 'collapse',
        name: 'RTL',
        key: 'rtl',
        path: '/rtl',
        component: <RTL />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Notifications',
        key: 'notifications',
        path: '/notifications',
        component: <Notifications />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Profile',
        key: 'profile',
        path: '/profile',
        component: <Profile />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Units',
        key: 'units',
        path: '/unidades',
        component: <Units />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Steps',
        key: 'steps',
        path: '/etapas',
        component: <Steps />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Sign In',
        key: 'sign-in',
        path: '/entrar',
        component: <Login />,
        logged: false,
    },
    {
        type: 'collapse',
        name: 'Gestão',
        key: 'orders',
        path: '/gestao',
        component: <Orders />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Gestão Funil',
        key: 'orders_funnel',
        path: '/gestao-funil',
        component: <OrdersKanban />,
        logged: true,
        showSideNav: false,
    },
    {
        type: 'collapse',
        name: 'Finanças',
        key: 'finance',
        path: '/financas',
        component: <Finances />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Treinamento',
        key: 'training',
        path: '/treinamentos',
        component: <TrainingUser />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Treinamentos',
        key: 'training_management',
        path: '/treinamento',
        component: <Training />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Assitir',
        key: 'watching',
        path: '/watching/:id',
        component: <Watching />,
        logged: true,
        showSideNav: false,
    },
    {
        type: 'collapse',
        name: 'Colaboradores',
        key: 'colaboradores',
        path: '/colaboradores',
        component: <Collaborators />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Consultores',
        key: 'consultants',
        path: '/consultores',
        component: <Consultants />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Alterar senha',
        key: 'change-pass',
        path: '/alterar-senha',
        component: <ChangePassword />,
        showSideNav: false,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Blog',
        key: 'blog',
        path: '/site/blog',
        component: <Posts />,
        logged: true,
    },
    {
        type: 'collapse',
        name: 'Leads',
        key: 'leads',
        path: '/site/leads',
        component: <Leads />,
        logged: true,
    },
]

export default paths
