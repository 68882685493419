import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Form, Modal, Row, Switch, Tabs } from 'antd'

import moment from 'moment'

import {
    franchiseeService,
    serviceService,
    simulationService,
    stageService,
} from '../../redux/services'

import { UIDrawerForm } from '../../components'
import { number_format } from '../../helpers/phpjs'

const config = {
    externalName: 'franqueado',
}

class Show extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        external: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            id: 0,
            item: {},
        }
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
            item: {},
        })

        stageService
            .show({ id })
            .then((response) => {
                let item = response.data

                this.setState({
                    isLoading: false,
                    item: item,
                })
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                    onOk: () => {
                        // Force close
                        return this.onClose()
                    },
                })
            })
    }

    resetFields = () => {
        this.setState({
            item: {},
        })
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    render() {
        const { visible } = this.props
        const { id, isLoading, item } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                showBtnSave={false}
                title={`Visualizar ${this.props.external ? config.externalName : 'registro'
                    } [${id}]`}
            >
                <Form layout="vertical">
                    <Tabs defaultActiveKey="general">
                        <Tabs.TabPane
                            forceRender
                            tab="Info. Gerais"
                            key="general"
                        >
                            <Row gutter={16}>
                                <Col xs={24} sm={24}>
                                    <Form.Item label="Nome">
                                        {item.name ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label="Ativo">
                                <Switch disabled checked={item.is_active} />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Criação">
                                        {moment(item.created_at).calendar()}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Última atualização">
                                        {moment(item.updated_at).calendar()}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Show
