import { toFormData } from 'helpers/form'
import { api } from '../../config/api'

const basePath = 'site/posts'

/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAll = (options) => {
    const options_default = {}

    // Merge config
    options = Object.assign({}, options_default, options)

    let params = []
    let params_qs = ''

    if (options.hasOwnProperty('page')) {
        params.push(`page=${options.page}`)
    }

    if (options.hasOwnProperty('limit')) {
        params.push(`limit=${options.limit}`)
    }

    if (options.hasOwnProperty('search')) {
        params.push(`search=${options.search}`)
    }

    if (options.hasOwnProperty('orderBy')) {
        params.push(`orderBy=${options.orderBy}`)
    }

    if (options.hasOwnProperty('is_active')) {
        params.push(`is_active=${options.is_active}`)
    }


    if (options.hasOwnProperty('created_at')) {
        options.created_at.forEach((item, index) => {
            params.push(`created_at[${index}]=${item}`)
        })
    }

    if (params.length) {
        params_qs = `?${params.join('&')}`
    }

    if (options.exportItems) {
        return api.get(`${basePath}/export${params_qs}`)
    } else {
        return api.get(`${basePath}${params_qs}`)
    }
}

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const create = (options) => {
    return api.post(basePath, toFormData(options));
};

/**
 * Edit
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
    return api.put(`${basePath}/${options.id}`, toFormData(options))
}

/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const show = (options) => {
    return api.get(`${basePath}/${options.id}`)
}

/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const showDetails = (options) => {
    return api.get(`${basePath}/${options.id}/details`)
}

/**
 * Delete
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const destroy = (id) => {
    return api.delete(`${basePath}/${id}`)
}

/**
 * Destroy Comment
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const destroyComment = (id) => {
    return api.delete(`${basePath}/comment/${id}`)
}
