import { api } from '../../config/api'

const basePath = 'orders'

/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAll = (options) => {
    const options_default = {}

    // Merge config
    options = Object.assign({}, options_default, options)

    let params = []
    let params_qs = ''

    if (options.hasOwnProperty('page')) {
        params.push(`page=${options.page}`)
    }

    if (options.hasOwnProperty('limit')) {
        params.push(`limit=${options.limit}`)
    }

    if (options.hasOwnProperty('search')) {
        params.push(`search=${options.search}`)
    }

    if (options.hasOwnProperty('orderBy')) {
        params.push(`orderBy=${options.orderBy}`)
    }

    if (options.hasOwnProperty('signed')) {
        params.push(`signed=${options.signed}`)
    }

    if (options.hasOwnProperty('user_id')) {
        params.push(`user_id=${options.user_id}`)
    }

    if (options.hasOwnProperty('created_at')) {
        options.created_at.forEach((item, index) => {
            params.push(`created_at[${index}]=${item}`)
        })
    }

    if (options.hasOwnProperty('stage_id')) {
        params.push(`stage_id=${options.stage_id}`)
    }

    if (options.hasOwnProperty('service_id')) {
        params.push(`service_id=${options.service_id}`)
    }

    if (params.length) {
        params_qs = `?${params.join('&')}`
    }

    if (options.hasOwnProperty('is_kanban')) {
        return api.get(`${basePath}/kanban${params_qs}`)
    }

    if (options.exportItems) {
        return api.get(`${basePath}/export${params_qs}`, {
            responseType: 'blob'
        })
    } else {
        return api.get(`${basePath}${params_qs}`)
    }
}

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const create = (options) => {
    return api.post(`${basePath}`, options)
}

/**
 * Edit
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
    return api.put(`${basePath}/${options.id}`, options)
}

/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const show = (options) => {
    return api.get(`${basePath}/${options.id}`)
}

/**
 * Delete
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const destroy = (options) => {
    return api.delete(`${basePath}/${options.id}`)
}
