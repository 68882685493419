import { REHYDRATE } from 'redux-persist'
import { authConstants } from './../constants'

const reducerKey = 'auth'

const defaultState = {
    isAuthenticated: false,
    isLoadingUserData: false,
    access_token: '',
    userData: {
        id: '',
        name: '',
        email: '',
        birth: '',
        company_name: '',
        document: '',
        phone: '',
        role: '',
        permissions: [],
        password_changed: true,
        hasUnit: {
            name: "",
            phone: "",
            email: "",
            street: "",
            district: "",
            number: "",
            city: "",
            state: "",
            zipcode: "",
        },
        viewConfig: {
            id: null,
            user_id: null,
            steps_view: [],
            background_color: null
        }
    },
}

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case REHYDRATE:
            let persistUpdate = {}

            if (action.payload && action.payload[reducerKey]) {
                const persistCache = action.payload[reducerKey]

                persistUpdate = {
                    isAuthenticated: persistCache.isAuthenticated,
                    access_token: persistCache.access_token,
                }

                if (persistCache.userData) {
                    persistUpdate.userData = {
                        id:
                            persistCache.userData.id ||
                            defaultState.userData.id,
                        name:
                            persistCache.userData.name ||
                            defaultState.userData.name,
                        email:
                            persistCache.userData.email ||
                            defaultState.userData.email,
                        birth:
                            persistCache.userData.birth ||
                            defaultState.userData.birth,
                        company_name:
                            persistCache.userData.company_name ||
                            defaultState.userData.company_name,
                        document:
                            persistCache.userData.document ||
                            defaultState.userData.document,
                        phone:
                            persistCache.userData.phone ||
                            defaultState.userData.phone,
                        role:
                            persistCache.userData.role ||
                            defaultState.userData.role,
                        permissions:
                            persistCache.userData.permissions ||
                            defaultState.userData.permissions,
                        password_changed:
                            persistCache.userData.password_changed ||
                            defaultState.userData.password_changed,
                        hasUnit:
                            persistCache.userData.hasUnit ||
                            defaultState.userData.hasUnit,
                        viewConfig:
                            persistCache.userData.viewConfig ||
                            defaultState.userData.viewConfig,
                    }
                }
            }

            return Object.assign({}, state, persistUpdate)

        case authConstants.LOGIN:
            return Object.assign({}, state, {
                isAuthenticated: true,
                access_token: `Bearer ${action.data.access_token}`,
                userData: {
                    ...state.userData,
                    id: action.data.id,
                    name: action.data.name,
                    email: action.data.email,
                    birth: action.data.birth,
                    company_name: action.data.company_name,
                    document: action.data.document,
                    phone: action.data.phone,
                    role: action.data.role,
                    permissions: action.data.permissions,
                    password_changed: action.data.password_changed,
                    hasUnit: action.data.hasUnit,
                    viewConfig: action.data.viewConfig,
                },
            })

        case authConstants.LOGOUT:
            return Object.assign({}, state, defaultState)

        case authConstants.USERDATA_REQUEST:
            return Object.assign({}, state, {
                isLoadingUserData: true,
            })

        case authConstants.USERDATA_SUCCESS:
            return Object.assign({}, state, {
                isLoadingUserData: false,
                userData: {
                    ...state.userData,
                    id: action.data.id,
                    name: action.data.name,
                    email: action.data.email,
                    birth: action.data.birth,
                    company_name: action.data.company_name,
                    document: action.data.document,
                    phone: action.data.phone,
                    role: action.data.role,
                    permissions: action.data.permissions,
                    password_changed: action.data.password_changed,
                    hasUnit: action.data.hasUnit,
                    viewConfig: action.data.viewConfig,
                },
            })

        case authConstants.USERDATA_ERROR:
            return Object.assign({}, state, {
                isLoadingUserData: false,
            })

        case authConstants.UPDATE_AVATAR:
            return Object.assign({}, state, {
                userData: {
                    ...state.userData,
                    avatar: action.data.avatar,
                },
            })

        default:
            return state
    }
}
