import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Form, Modal, Row, Tabs, List, Avatar, Empty, Popconfirm, Button, Tag } from 'antd'
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons'

import moment from 'moment'

import { notificationService } from '../../redux/services'
import { UIDrawerForm } from '../../components'

class Notifications extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            notifications: []
        }
    }

    onOpen = () => {
        this.setState({
            isLoading: true,
            notifications: []
        })

        notificationService
            .getAll()
            .then((response) => {
                this.setState({
                    isLoading: false,
                    notifications: response.data.data
                })
            })
            .catch((error) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(error),
                    onOk: () => {
                        // Force close
                        return this.onClose()
                    }
                })
            })
    }

    resetFields = () => {
        this.setState({
            notifications: []
        })
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    markAsRead = (notificationId) => {
        this.setState({ isLoading: true });

        notificationService
            .markAsRead(notificationId)
            .then(() => {
                const updatedNotifications = this.state.notifications.map(notification => {
                    if (notification.id === notificationId) {
                        return { ...notification, read_at: moment().format() };
                    }
                    return notification;
                });

                this.setState({
                    isLoading: false,
                    notifications: updatedNotifications
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                Modal.error({
                    title: 'Erro ao marcar notificação como lida',
                    content: String(error),
                });
            });
    }

    deleteNotification = (notificationId) => {
        this.setState({ isLoading: true });

        notificationService
            .destroy(notificationId)
            .then(() => {
                const updatedNotifications = this.state.notifications.filter(
                    notification => notification.id !== notificationId
                );

                this.setState({
                    isLoading: false,
                    notifications: updatedNotifications
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                Modal.error({
                    title: 'Erro ao excluir notificação',
                    content: String(error),
                });
            });
    }

    render() {
        const { visible } = this.props
        const { isLoading, notifications } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                showBtnSave={false}
                title="Notificações"
            >
                <Form layout="vertical">
                    <Tabs defaultActiveKey="all">
                        <Tabs.TabPane
                            forceRender
                            tab="Todas"
                            key="all"
                        >
                            <Row gutter={16}>
                                <Col xs={24} sm={24}>
                                    {notifications && notifications.length > 0 ? (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={notifications}
                                            renderItem={(notification) => (
                                                <List.Item
                                                    actions={[
                                                        !notification.read_at && (
                                                            <Button
                                                                type="text"
                                                                title="Marcar como lida"
                                                                icon={<CheckOutlined />}
                                                                onClick={() => this.markAsRead(notification.id)}
                                                            />
                                                        ),
                                                        <Popconfirm
                                                            title="Excluir notificação"
                                                            description="Tem certeza que deseja excluir esta notificação?"
                                                            onConfirm={() => this.deleteNotification(notification.id)}
                                                            okText="Sim"
                                                            cancelText="Não"
                                                        >
                                                            <Button
                                                                type="text"
                                                                danger
                                                                icon={<DeleteOutlined />}
                                                            />
                                                        </Popconfirm>
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        avatar={<Avatar src={`/avatars/avatar${Math.floor(Math.random() * 6) + 1}.png`} />}
                                                        title={notification.title}
                                                        description={notification.message}
                                                    />
                                                    <div>
                                                        <div style={{ marginBottom: 5 }}>
                                                            {moment(notification.created_at).format('DD/MM/YYYY HH:mm')}
                                                        </div>
                                                        <Tag color={notification.read_at ? 'default' : 'processing'}>
                                                            {notification.read_at ? 'Lida' : 'Não lida'}
                                                        </Tag>
                                                    </div>
                                                </List.Item>
                                            )}
                                        />
                                    ) : (
                                        <Empty description="Nenhuma notificação encontrada" />
                                    )}
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            forceRender
                            tab="Não lidas"
                            key="unread"
                        >
                            <Row gutter={16}>
                                <Col xs={24} sm={24}>
                                    {notifications && notifications.filter(n => !n.read_at).length > 0 ? (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={notifications.filter(n => !n.read_at)}
                                            renderItem={(notification) => (
                                                <List.Item
                                                    actions={[
                                                        <Button
                                                            type="text"
                                                            title="Marcar como lida"
                                                            icon={<CheckOutlined />}
                                                            onClick={() => this.markAsRead(notification.id)}
                                                        />,
                                                        <Popconfirm
                                                            title="Excluir notificação"
                                                            description="Tem certeza que deseja excluir esta notificação?"
                                                            onConfirm={() => this.deleteNotification(notification.id)}
                                                            okText="Sim"
                                                            cancelText="Não"
                                                        >
                                                            <Button
                                                                type="text"
                                                                danger
                                                                icon={<DeleteOutlined />}
                                                            />
                                                        </Popconfirm>
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        avatar={<Avatar src={`/avatars/avatar${Math.floor(Math.random() * 6) + 1}.png`} />}
                                                        title={notification.title}
                                                        description={notification.message}
                                                    />
                                                    <div>{moment(notification.created_at).format('DD/MM/YYYY HH:mm')}</div>
                                                </List.Item>
                                            )}
                                        />
                                    ) : (
                                        <Empty description="Nenhuma notificação não lida" />
                                    )}
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Notifications
