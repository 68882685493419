import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Spin,
    Switch,
    message,
} from 'antd'

import { kanbanService, orderService, stageService } from './../../redux/services'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Config extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isSending: false,
            id: 0,
            stages: [],
        }
    }

    /**
     * Ao abrir o modal, carrega os stages e seta os valores atuais.
     * Supondo que cada stage já venha com uma propriedade "visible"
     * indicando se ele está ativo ou não.
     */
    onOpen = (item) => {
        console.log("config:onOpen: ", this.props.config)

        this.setState(
            {
                isLoading: true,
                id: item.id,
            },
            () => {
                stageService
                    .getAll()
                    .then((response) => {
                        // Supondo que this.props.config seja o array com os IDs ativos
                        // Exemplo: [1, 3, 4]
                        const activeSteps = this.props.config || [];

                        // Mapeia os stages retornados, definindo visible com base no activeSteps
                        const stages = response.data.data.map((stage) => {
                            return {
                                ...stage,
                                visible: activeSteps.includes(stage.id),
                            };
                        });

                        this.setState(
                            {
                                isLoading: false,
                                stages,
                            },
                            () => {
                                // Define o valor inicial do campo "steps_view" com os IDs dos stages ativos
                                this.form.setFieldsValue({ steps_view: activeSteps });
                            }
                        );
                    })
                    .catch((err) => {
                        Modal.error({
                            title: 'Ocorreu um erro!',
                            content: String(err),
                        });
                    });
            }
        );
    };


    /**
     * Atualiza o estado e o valor do campo oculto "steps_view" conforme
     * o switch for alterado.
     */
    handleSwitchChange = (id, checked) => {
        this.setState(
            (prevState) => {
                const updatedStages = prevState.stages.map((stage) => {
                    if (stage.id === id) {
                        return { ...stage, visible: checked }
                    }
                    return stage
                })
                return { stages: updatedStages }
            },
            () => {
                const stepsView = this.state.stages
                    .filter((s) => s.visible)
                    .map((s) => s.id)
                this.form.setFieldsValue({ steps_view: stepsView })
            }
        )
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        this.resetFields()
        this.props.onClose()
    }

    /**
     * Na submissão, monta o payload conforme:
     * { id: <ID>, steps_view: [<array de IDs dos stages ativos>] }
     */
    onFinish = (values) => {
        this.setState({ isSending: true })

        const data = {
            id: this.state.id,
            steps_view: values.steps_view,
        }

        kanbanService
            .create(data)
            .then((response) => {
                this.setState({ isSending: false })
                this.resetFields()
                message.success('Registro atualizado com sucesso.')
                this.props.onComplete()

                this.props.refreshUserData()
            })
            .catch((err) => {
                this.setState({ isSending: false })
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(err),
                })
            })
    }

    render() {
        const { visible } = this.props
        const { id, isLoading, isSending, stages } = this.state

        return (
            <Modal
                open={visible}
                width={500}
                onCancel={this.onClose}
                closable
                title={`Editar funil`}
                onOk={() => this.form.submit()}
                okButtonProps={{
                    loading: isSending,
                }}
            >
                {isLoading ? (
                    <div className="loading-container">
                        <Spin />
                    </div>
                ) : (
                    <Form
                        ref={(el) => (this.form = el)}
                        id={formId}
                        layout="vertical"
                        onFinish={this.onFinish}
                    >
                        <Divider orientation="left">
                            Configurações da visualização
                        </Divider>
                        {stages.map((item) => (
                            <Row
                                key={item.id}
                                align="middle"
                                style={{ marginBottom: 8, padding: '0 10px' }}
                            >
                                <Col span={6}>
                                    <Switch
                                        checked={item.visible}
                                        onChange={(checked) =>
                                            this.handleSwitchChange(item.id, checked)
                                        }
                                    />
                                </Col>
                                <Col span={18}>
                                    <span>{item.name}</span>
                                </Col>
                            </Row>
                        ))}
                        {/* Campo oculto que armazenará o array com os IDs dos stages ativos */}
                        <Form.Item name="steps_view" hidden>
                            <Input />
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        )
    }
}

export default Config

