import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Form, Input, Modal, Row, Select, Spin, message } from 'antd'

import { UIDrawerForm } from '../../../components'
import { customerService } from 'redux/services'
import { NumericFormat } from 'react-number-format'
import axios from 'axios'
import { serviceService } from 'redux/services'
import { stageService } from 'redux/services'
import { orderService } from 'redux/services'
import { franchiseeService } from 'redux/services'
import { API_ERRO_TYPE_CANCEL } from 'config/general'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Create extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isSending: false,
            taxRegimes: [],
            customersLoading: false,
            customers: [],
            franchiseesLoading: false,
            franchisees: [],
            services: [],
            stages: [],
            honorary: 0,
        }
    }

    onOpen = () => {
        Promise.all([serviceService.getAll(), stageService.getAll()])
            .then(([serviceResponse, stageResponse]) => {
                this.setState({
                    isLoading: false,
                    services: serviceResponse.data.data,
                    stages: stageResponse.data.data,
                })
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const data = { ...values, honorary: this.state.honorary }

        orderService
            .create(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro cadastrado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchCustomers = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                customersLoading: false,
                customers: [],
            })

            return false
        }

        this.setState({
            customersLoading: true,
        })

        customerService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    customersLoading: false,
                    customers: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    customersLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    fetchFranchisees = (value) => {
        if (this._axiosCancelToken) {
            this._axiosCancelToken.cancel('Only one request allowed at a time.')
        }

        this._axiosCancelToken = axios.CancelToken.source()

        if (!value.trim().length) {
            this.setState({
                franchiseesLoading: false,
                franchisees: [],
            })

            return false
        }

        this.setState({
            franchiseesLoading: true,
        })

        franchiseeService
            .getAll({
                search: value,
                cancelToken: this._axiosCancelToken.token,
            })
            .then((response) => {
                this.setState({
                    franchiseesLoading: false,
                    franchisees: response.data.data,
                })
            })
            .catch((data) => {
                if (data.error_type === API_ERRO_TYPE_CANCEL) return null

                this.setState({
                    franchiseesLoading: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { visible, userData } = this.props

        const {
            isLoading,
            isSending,
            customers,
            customersLoading,
            services,
            stages,
            franchiseesLoading,
            franchisees,
        } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={500}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title="Inserir nova comissão"
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                    initialValues={{
                        stage_id: 1,
                    }}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="customer_id"
                                label="Cliente"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Pesquise a cliente"
                                    notFoundContent={
                                        customersLoading ? (
                                            <Spin
                                                indicator={
                                                    <i className="fad fa-spinner-third fa-spin" />
                                                }
                                            />
                                        ) : null
                                    }
                                    onSearch={this.fetchCustomers}
                                    showSearch
                                >
                                    {customers.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.company_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="service_id"
                                label="Serviço"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Serviço"
                                >
                                    {services.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="stage_id"
                                label="Etapa"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={false}
                                    allowClear
                                    placeholder="Etapa"
                                >
                                    {stages.map((item, index) => (
                                        <Select.Option
                                            key={index}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        {userData.role === 'admin' && (
                            <Col xl={12} sm={12}>
                                <Form.Item
                                    name="user_id"
                                    label="Franqueado"
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Campo obrigatório.',
                                        },
                                    ]}
                                >
                                    <Select
                                        filterOption={false}
                                        allowClear
                                        placeholder="Pesquiseo franqueado"
                                        notFoundContent={
                                            franchiseesLoading ? (
                                                <Spin
                                                    indicator={
                                                        <i className="fad fa-spinner-third fa-spin" />
                                                    }
                                                />
                                            ) : null
                                        }
                                        onSearch={this.fetchFranchisees}
                                        showSearch
                                    >
                                        {franchisees.map((item, index) => (
                                            <Select.Option
                                                key={index}
                                                value={item.id}
                                            >
                                                {item.company_name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        <Col xl={12} sm={12}>
                            <Form.Item
                                name="honorary"
                                label="Honorário %"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <NumericFormat
                                    className="ant-input ant-input-outlined"
                                    suffix="%"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    value={this.state.honorary}
                                    onValueChange={({ floatValue }) => {
                                        this.setState({
                                            honorary: floatValue,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item
                                name="obs"
                                label="Observação"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obrigatório.',
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Modal open={this.state.iFrame}>
                    <iframe src={this.state.iFrame} />
                </Modal>
            </UIDrawerForm>
        )
    }
}

export default Create
