import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Form, Modal, Row, Switch, Tabs, List, Avatar, Empty, Popconfirm, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import moment from 'moment'

import {
    franchiseeService,
    postsService,
    serviceService,
    simulationService,
} from '../../redux/services'

import { UIDrawerForm } from '../../components'
import { number_format } from '../../helpers/phpjs'

const config = {
    externalName: 'franqueado',
}

class Show extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        external: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            id: 0,
            item: {},
        }
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
            item: {},
        })

        postsService
            .showDetails({ id })
            .then((response) => {
                let item = response.data

                this.setState({
                    isLoading: false,
                    item: item,
                })
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                    onOk: () => {
                        // Force close
                        return this.onClose()
                    },
                })
            })
    }

    resetFields = () => {
        this.setState({
            item: {},
        })
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    deleteComment = (commentId) => {
        this.setState({ isLoading: true });

        postsService
            .destroyComment(commentId)
            .then(() => {
                // Atualiza a lista de comentários após excluir
                return this.onOpen(this.state.id);
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                Modal.error({
                    title: 'Erro ao excluir comentário',
                    content: String(error),
                });
            });
    }

    render() {
        const { visible } = this.props
        const { id, isLoading, item } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                showBtnSave={false}
                title={`Visualizar ${this.props.external ? config.externalName : 'registro'
                    } [${id}]`}
            >
                <Form layout="vertical">
                    <Tabs defaultActiveKey="general">
                        {/* <Tabs.TabPane
                            forceRender
                            tab="Info. Gerais"
                            key="general"
                        >
                            <Row gutter={16}>
                                <Col xs={24} sm={24}>
                                    <Form.Item label="Nome">
                                        {item.name ?? '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label="Ativo">
                                <Switch disabled checked={item.is_active} />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Criação">
                                        {moment(item.created_at).calendar()}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item label="Última atualização">
                                        {moment(item.updated_at).calendar()}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane> */}
                        <Tabs.TabPane
                            forceRender
                            tab="Comentários"
                            key="comments"
                        >
                            <Row gutter={16}>
                                <Col xs={24} sm={24}>
                                    {item.comments && item.comments.length > 0 ? (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={item.comments}
                                            renderItem={(comment) => (
                                                <List.Item
                                                    actions={[
                                                        <Popconfirm
                                                            title="Excluir comentário"
                                                            description="Tem certeza que deseja excluir este comentário?"
                                                            onConfirm={() => this.deleteComment(comment.id)}
                                                            okText="Sim"
                                                            cancelText="Não"
                                                        >
                                                            <Button
                                                                type="text"
                                                                danger
                                                                icon={<DeleteOutlined />}
                                                            />
                                                        </Popconfirm>
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        avatar={<Avatar src={`/avatars/avatar${Math.floor(Math.random() * 6) + 1}.png`} />}
                                                        title={comment.name || "Usuário"}
                                                        description={comment.text}
                                                    />
                                                    <div>{moment(comment.created_at).format('DD/MM/YYYY HH:mm')}</div>
                                                </List.Item>
                                            )}
                                        />
                                    ) : (
                                        <Empty description="Nenhum comentário encontrado" />
                                    )}
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            forceRender
                            tab="Curtidas"
                            key="likes"
                        >
                            <Row gutter={16}>
                                <Col xs={24} sm={24}>
                                    {item.likes && item.likes.length > 0 ? (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={item.likes}
                                            renderItem={(like) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={<Avatar src={`/avatars/avatar${Math.floor(Math.random() * 6) + 1}.png`} />}
                                                        title={like.email || "Usuário"}
                                                        description={like.ip}
                                                    />
                                                    <div>{moment(like.created_at).format('DD/MM/YYYY HH:mm')}</div>
                                                </List.Item>
                                            )}
                                        />
                                    ) : (
                                        <Empty description="Nenhuma curtida encontrada" />
                                    )}
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Show
