import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Card, Checkbox, Col, Form, Input, List, message, Modal, Row, Select, Spin, Switch } from "antd";

import { franchiseeService, permissionService, trainingService } from "./../../redux/services";

import { UIDrawerForm } from "./../../components";
import axios from "axios";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
			allAccess: true,
			users: [],
			franchiseesLoading: false,
			franchisees: [],
			uploadStatus: 0
		};
	}

	onOpen = (id) => { }

	resetFields = () => {
		this.form.resetFields();

		this.setState({
			users: []
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {

		this.setState({
			isSending: true,
		});

		const data = { ...values, blocked: !this.state.allAccess };

		if (this.state.users.length > 0) {
			data.users = this.state.users.map((user) => user.id);
		}

		trainingService.create(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro cadastrado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onChangeAccess = (value) => {
		this.setState({
			allAccess: value,
		});
	}

	fetchFranchisees = (value) => {
		if (this._axiosCancelToken) {
			this._axiosCancelToken.cancel('Only one request allowed at a time.')
		}

		this._axiosCancelToken = axios.CancelToken.source()

		if (!value.trim().length) {
			this.setState({
				franchiseesLoading: false,
				franchisees: [],
			})

			return false
		}

		this.setState({
			franchiseesLoading: true,
		})

		franchiseeService
			.getAll({
				search: value,
				cancelToken: this._axiosCancelToken.token,
			})
			.then((response) => {
				this.setState({
					franchiseesLoading: false,
					franchisees: response.data.data,
				})
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null

				this.setState({
					franchiseesLoading: false,
				})

				Modal.error({
					title: 'Ocorreu um erro!',
					content: String(data),
				})
			})
	}

	setUser = (value) => {
		const user = this.state.franchisees.find(franchisee => franchisee.id === value)

		if (!user) return null

		if (this.state.users.find(franchisee => franchisee.id === value)) {
			return null
		}

		this.setState(state => ({
			users: [...state.users, user],
		}));
	}

	removeUser = (value) => {
		const users = this.state.users

		users.splice(value, 1)

		this.setState(state => ({
			users
		}));
	}

	render() {
		const { visible } = this.props;
		const { isLoading, isSending, allAccess, users, franchiseesLoading, franchisees, uploadStatus } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Inserir novo treinamento">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Row gutter={16}>
						<Col xl={24} sm={24}>
							<Form.Item name="name" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xl={24} sm={24}>
							<Form.Item
								label="Todos tem acesso?"
								name="blocked"
								valuePropName="checked"
							>
								<Switch onChange={this.onChangeAccess} defaultChecked />
							</Form.Item>
						</Col>
					</Row>
					{!allAccess && (
						<>
							<Row gutter={16}>
								<Col xl={24} sm={24}>
									<Form.Item>
										<Select
											filterOption={false}
											allowClear
											placeholder={`Pesquise o franqueado`}
											notFoundContent={
												franchiseesLoading ? (
													<Spin
														indicator={
															<i className="fad fa-spinner-third fa-spin" />
														}
													/>
												) : null
											}
											onSearch={this.fetchFranchisees}
											showSearch
											onChange={(value) => this.setUser(value)}
										>
											{franchisees.map((item, index) => (
												<Select.Option
													key={index}
													value={item.id}
												>
													{item.company_name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Card title="Franqueados selecionados">
								<List
									dataSource={users}
									renderItem={(item, index) => (
										<List.Item key={index}>
											<List.Item.Meta
												title={<span>{item.name}</span>}
												description={`${item.company_name}`}
											/>
											<Button icon={<i className="fal fa-trash" />} onClick={() => this.removeUser(index)} />
										</List.Item>
									)}
								/>
							</Card>
						</>
					)}
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Create;
