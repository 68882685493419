import { api } from "config/api"

const basePath = 'notifications'
/**
 * Obter todas as notificações
 * 
 * @param {Object} data 
 * @returns {Promise}
 */
export const getAll = (data = {}) => {
    return api.get(`${basePath}`, { params: data })
}

/**
 * Marcar notificação como lida
 * 
 * @param {number|string} id 
 * @returns {Promise}
 */
export const markAsRead = (id) => {
    return api.patch(`${basePath}/${id}/read`)
}

/**
 * Marcar todas as notificações como lidas
 * 
 * @returns {Promise}
 */
export const markAllAsRead = () => {
    return api.patch(`${basePath}/read-all`)
}

/**
 * Excluir uma notificação
 * 
 * @param {number|string} id 
 * @returns {Promise}
 */
export const destroy = (id) => {
    return api.delete(`${basePath}/${id}`)
}

/**
 * Excluir todas as notificações
 * 
 * @returns {Promise}
 */
export const destroyAll = () => {
    return api.delete(`${basePath}/clear-all`)
}

/**
 * Obter contagem de notificações não lidas
 * 
 * @returns {Promise}
 */
export const getUnreadCount = () => {
    return api.get(`${basePath}/unread-count`)
}
