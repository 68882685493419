import React, { useState } from 'react';
import { Radio } from 'antd';
import moment from 'moment';

const DateFilter = ({ onDateFilterChange, openFiltersModal }) => {
    const [selected, setSelected] = useState('7');

    const handleChange = (e) => {
        const value = e.target.value;
        setSelected(value);

        if (value === '7') {

            const today = moment();
            const startDate = moment().subtract(7, 'days').startOf('day');
            onDateFilterChange([startDate, today]);
        } else if (value === '30') {
            const today = moment();
            const startDate = moment().subtract(30, 'days').startOf('day');
            onDateFilterChange([startDate, today]);
        } else if (value === 'custom') {
            openFiltersModal();
        }
    };

    return (
        <Radio.Group
            onChange={handleChange}
            value={selected}
            optionType="button"
            buttonStyle="solid"
            options={[
                { label: '7 dias', value: '7' },
                { label: '30 dias', value: '30' },
                { label: 'Selecionar data', value: 'custom' },
            ]}
        />
    );
};

export default DateFilter;
