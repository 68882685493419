/**
=========================================================
 * Material Dashboard 2 React - v2.2.0
=========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";

// react-router components
import { Link, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarIconButton, navbarMobileMenu, navbarRow, } from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import { setMiniSidenav, setOpenConfigurator, setTransparentNavbar, useMaterialUIController, } from "context";
import MDInput from "components/MDInput";

function DashboardNavbar({ absolute, light, isMini, title, extra = false, renderExtra }) {
	const [navbarType, setNavbarType] = useState();
	const [controller, dispatch] = useMaterialUIController();
	const { miniSidenav, transparentNavbar, openConfigurator, darkMode } = controller;
	const [openMenu, setOpenMenu] = useState(false);
	const route = useLocation().pathname.split("/").slice(1);

	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
	const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
	const handleCloseMenu = () => setOpenMenu(false);

	// Render the notifications menu
	const renderMenu = () => (
		<Menu
			anchorEl={openMenu}
			anchorReference={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			open={Boolean(openMenu)}
			onClose={handleCloseMenu}
			sx={{ mt: 2 }}
		>
			<NotificationItem icon={<Icon>send</Icon>} title="Enviar notificações" />
			<NotificationItem icon={<Icon>email</Icon>} title="Ver notificações" />
		</Menu>
	);

	// Styles for the navbar icons
	const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
		color: () => {
			let colorValue = light || darkMode ? white.main : dark.main;

			if (transparentNavbar && !light) {
				colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
			}

			return colorValue;
		},
	});

	return (
		<AppBar
			color="inherit"
			sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
			style={{ position: "relative", zIndex: 9 }}
		>
			<Toolbar sx={(theme) => navbarContainer(theme)}>
				<MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
					<Breadcrumbs icon="home" title={title ?? route} route={route} light={light} />
				</MDBox>
				<div style={{width: "100%"}}>

				{extra && renderExtra}
				</div>
			</Toolbar>
		</AppBar>
	);
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
	absolute: false,
	light: false,
	isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
	absolute: PropTypes.bool,
	light: PropTypes.bool,
	isMini: PropTypes.bool,
};

export default DashboardNavbar;
